import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FiSend, FiRefreshCw, FiTrendingUp, FiUsers, FiMail, FiLinkedin, FiTwitter, FiGithub, FiShield, FiLayers } from 'react-icons/fi';

const ServiceItem = ({ title, description, icon }) => (
  <motion.div 
    className="p-6 bg-gray-800 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="text-emerald-500 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2 text-gray-100">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </motion.div>
);

const ComingSoon = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      console.log('Email submitted:', email);
      setError('');
    } else {
      setError('Please enter a valid email address');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-900 to-black text-white">
      <main className="flex-grow container mx-auto px-4 py-16">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="text-center mb-16"
        >
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="inline-block mb-8"
          >
            <div className="text-4xl sm:text-3xl md:text-6xl lg:text-7xl font-bold">
              <span className="text-emerald-500">429</span>
              <span className="text-gray-300">.agency</span>
            </div>
            <div className="text-lg sm:text-xl md:text-2xl mt-2">
              <span className="bg-gradient-to-r from-gray-300 to-emerald-500 text-transparent bg-clip-text">
                Overload for your business.
              </span>
            </div>
          </motion.div>
          
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-6">
            AI-Powered Customer Experience Evolution
          </h1>
          
          <p className="text-base sm:text-lg md:text-xl text-gray-300 max-w-3xl mx-auto mb-12">
            Inspired by the HTTP 429 "Too Many Requests" status code, we're here to elevate your customer support to handle any volume of requests with AI-driven efficiency.
          </p>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.8 }}
            className="bg-gray-800 p-6 sm:p-8 rounded-2xl shadow-2xl max-w-xl mx-auto"
          >
            <h2 className="text-xl sm:text-2xl font-bold mb-4">Be the First to Experience</h2>
            <p className="text-sm sm:text-base text-gray-300 mb-6">Join our exclusive list for early access to our revolutionary AI customer support solution.</p>
            <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="flex-grow p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500"
                required
              />
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                className="px-6 py-3 bg-emerald-500 text-white rounded-lg font-semibold hover:bg-emerald-600 transition-colors duration-300"
              >
                Notify Me
              </motion.button>
            </form>
            {error && <p className="text-red-400 mt-2 text-sm">{error}</p>}
            <p className="text-gray-400 text-xs mt-4">By signing up, you agree to our Terms and Privacy Policy.</p>
          </motion.div>
        </motion.div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 mb-16">
          <ServiceItem 
            icon={<FiUsers size={32} />}
            title="AI-Powered Engagement"
            description="Leverage our advanced AI models for unparalleled customer service across multiple channels."
          />
          <ServiceItem 
            icon={<FiRefreshCw size={32} />}
            title="Continuous Learning"
            description="Experience growth with our self-improving AI systems, constantly adapting to your business needs."
          />
          <ServiceItem 
            icon={<FiTrendingUp size={32} />}
            title="Customized Agents"
            description="Tailor-made AI agents for each client, trained on your specific knowledge base and processes."
          />
          <ServiceItem 
            icon={<FiSend size={32} />}
            title="Multi-Channel Support"
            description="Seamlessly integrate AI support across chat, email, social media, and phone channels."
          />
          <ServiceItem 
            icon={<FiShield size={32} />}
            title="Data Security & Privacy"
            description="Robust security measures ensuring your data and customer information remains protected and private."
          />
          <ServiceItem 
            icon={<FiLayers size={32} />}
            title="Scalable Infrastructure"
            description="Cloud-native architecture designed to handle growing demands and multiple customized agents simultaneously."
          />
        </div>
      </main>

      <footer className="bg-gray-900 text-gray-400 py-6 sm:py-8 mt-auto">
        <div className="container mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
          <div className="mb-4 sm:mb-0 text-center sm:text-left">
            <p>&copy; 2024 429.agency. All rights reserved.</p>
            <p>272 Bath Street, Glasgow G2 4JR, UK</p>
          </div>
          <div className="flex space-x-4">
            <a href="mailto:hi@429.agency" className="hover:text-emerald-500 transition-colors duration-300"><FiMail size={20} /></a>
            <a href="#" className="hover:text-emerald-500 transition-colors duration-300"><FiLinkedin size={20} /></a>
            <a href="#" className="hover:text-emerald-500 transition-colors duration-300"><FiTwitter size={20} /></a>
            <a href="#" className="hover:text-emerald-500 transition-colors duration-300"><FiGithub size={20} /></a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ComingSoon;